import React from 'react';
import { postEmpty, getDataValueAsBoolFromElement } from '@utils';

import { MenuDropdown, MenuDropdownItem, Avatar } from '@components';
import { useToast } from '@components/Toast';
import { useBreakpoint } from '@hooks';
import { Customer, Site } from '../types';

import FuzzySearchBar from './FuzzySearch';

type TopbarProps = {
  open: boolean;
  setOpen: (boolean) => void;
  pageDomainName: string;
  clientList: Customer[];
  accountsList: Site[];
  pageAccountSlug: string;
  pageClientSlug: string;
  userIsAuthenticated: boolean;
  userCanCreateIssue: boolean;
  hasManagementAPIToken: boolean;
  userData: {
    firstName: string;
    lastName: string;
    userInitials: string;
    email: string;
  };
};

const navigateToClientDashboard = () => {
  return window.location.assign(`/`);
};

const Topbar = ({
  open,
  setOpen,
  clientList,
  accountsList,
  pageDomainName,
  pageAccountSlug,
  pageClientSlug,
  userIsAuthenticated,
  userCanCreateIssue,
  hasManagementAPIToken,
  userData,
}: TopbarProps): JSX.Element => {
  const toast = useToast();
  const breakpoint = useBreakpoint();

  const userCanAccessFuzzySearch = getDataValueAsBoolFromElement(
    'navigation-react-target',
    'data-can-access-fuzzy-search',
  );

  const onLogout = () => {
    postEmpty(`/accounts/logout/`)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.clear();
          window.location.assign(res.url);
        }
      })
      .catch((e) => {
        toast.error('Login failed. An unknown error occured.');
      });
  };

  return (
    <nav
      aria-label="topbar-navigation"
      role="navigation"
      className="absolute left-0 top-0 z-30 flex h-16 w-full flex-row bg-white px-4 py-2 shadow-sm"
    >
      <div className="align-center flex h-full justify-between bg-white text-gray-100 md:hidden">
        <button
          className="px-2 focus:outline-none"
          onClick={() => setOpen(!open)}
        >
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="black"
            viewBox="0 0 24 24"
            stroke="black"
            aria-label="Menu"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      <ul className="flex w-full flex-row items-center justify-between text-sm">
        <li key="logo" onClick={() => navigateToClientDashboard()}>
          <a href="/">
            <img
              className="h-8"
              src={
                breakpoint === 'sm'
                  ? `/assets/images/searchpilot-mark.png`
                  : `/assets/images/searchpilot.png`
              }
              alt={'SearchPilot logo'}
            />
          </a>
        </li>
        <span className="flex flex-row items-center justify-around">
          {userCanAccessFuzzySearch ? (
            <li>
              <FuzzySearchBar
                clientList={clientList}
                accountsList={accountsList}
                pageClientSlug={pageClientSlug}
                pageAccountSlug={pageAccountSlug}
              />
            </li>
          ) : null}
          <li key="publish-btn-options">
            {pageAccountSlug && (
              <MenuDropdown key="publish" text="Publish Site">
                <MenuDropdownItem
                  label={`Publish to ${pageDomainName}`}
                  onClick={() => {
                    window.location.assign(`/${pageAccountSlug}/publish/live`);
                  }}
                />
              </MenuDropdown>
            )}
          </li>

          {userIsAuthenticated ? (
            <>
              <li key="docs" className="ml-4">
                <a
                  href="/docs"
                  className="text-sp-neutral font-semibold"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Documentation
                </a>
              </li>

              {/* TO DO: Comment taken from old nav template, user notification list */}

              <li key="user-btn-options" className="ml-4">
                <MenuDropdown avatarInitials={userData.userInitials}>
                  <div className="flex items-center px-4 py-3">
                    <Avatar initials={userData.userInitials} />
                    <dl className="ml-3">
                      <dt className="text-sp-neutral text-sm font-semibold">
                        {userData.firstName} {userData.lastName}
                      </dt>
                      <dt className="text-sp-neutral-500 text-sm">
                        {userData.email}
                      </dt>
                    </dl>
                  </div>
                  <hr className="sp-divider" />
                  {userCanCreateIssue && (
                    <>
                      <MenuDropdownItem
                        label={'Create Issue'}
                        onClick={() =>
                          window.location.assign(`/management/create_issue`)
                        }
                      />
                      <hr className="sp-divider" />
                    </>
                  )}
                  {hasManagementAPIToken && (
                    <>
                      <MenuDropdownItem
                        label={'API Token'}
                        onClick={() =>
                          window.location.assign(`/management/api_token`)
                        }
                      />
                      <hr className="sp-divider" />
                    </>
                  )}
                  <MenuDropdownItem
                    leftIcon="FiSettings"
                    label={'Change Password'}
                    onClick={() =>
                      window.location.assign(`/accounts/change_password`)
                    }
                  />
                  <MenuDropdownItem
                    leftIcon="FiLock"
                    label={'Manage 2FA'}
                    onClick={() => window.location.assign(`/accounts/2fa`)}
                  />
                  <MenuDropdownItem
                    leftIcon="FiLogOut"
                    label={'Log out'}
                    onClick={() => onLogout()}
                  />
                </MenuDropdown>
              </li>
            </>
          ) : null}
        </span>
      </ul>
    </nav>
  );
};

export default Topbar;
