import React, { useCallback, useEffect, useState } from 'react';

import { getDataValueFromElement, getDataValueAsBoolFromElement } from '@utils';

import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Customer, Site, SimpleSite } from './types';

const Navigation = ({
  customers,
  sites,
}: {
  customers: Customer[];
  sites: Site[];
}) => {
  const pageClientSlug = getDataValueFromElement(
    'navigation-react-target',
    'data-client-slug',
  );

  const pageAccountSlug = getDataValueFromElement(
    'navigation-react-target',
    'data-account-slug',
  );

  const canEditReports = getDataValueAsBoolFromElement(
    'navigation-react-target',
    'data-can-edit-reports',
  );

  const managementAccessEnabled = getDataValueAsBoolFromElement(
    'navigation-react-target',
    'data-can-access-management',
  );

  const isSuperuser = getDataValueAsBoolFromElement(
    'navigation-react-target',
    'data-is-superuser',
  );

  const pageDomainName = getDataValueFromElement(
    'navigation-react-target',
    'data-domain-name',
  );

  const userIsAuthenticated = getDataValueAsBoolFromElement(
    'navigation-react-target',
    'data-is-user-authenticated',
  );

  const userCanCreateIssue = getDataValueAsBoolFromElement(
    'navigation-react-target',
    'data-can-create-issue',
  );

  const hasManagementAPIToken = getDataValueAsBoolFromElement(
    'navigation-react-target',
    'data-has-management-api-token',
  );

  const userFirstName =
    getDataValueFromElement('navigation-react-target', 'data-first-name') ||
    '?';

  const userLastName =
    getDataValueFromElement('navigation-react-target', 'data-last-name') || '?';

  const userEmail = getDataValueFromElement(
    'navigation-react-target',
    'data-user-email',
  );

  const userData = {
    firstName: userFirstName,
    lastName: userLastName,
    userInitials: `${userFirstName.charAt(0)}${userLastName.charAt(0)}`,
    email: userEmail ?? '',
  };

  const [selectedClientSlug, setSelectedClientSlug] = useState<string>(
    pageClientSlug ? pageClientSlug : '',
  );

  const [selectedAccountSlug, setSelectedAccountSlug] = useState<string>(
    pageAccountSlug ? pageAccountSlug : '',
  );

  const [selectedCustomerAccounts, setSelectedCustomerAccounts] = useState<
    SimpleSite[]
  >([]);

  const handleChange = (value: string) => {
    setSelectedClientSlug(value);
  };

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (customers.length === 1) {
      setSelectedClientSlug(customers[0].slug);
    }

    if (selectedClientSlug) {
      let selectedCustomerAccounts = customers?.find(
        (customer) => customer.slug === selectedClientSlug,
      )?.accounts;

      if (selectedCustomerAccounts) {
        setSelectedCustomerAccounts(selectedCustomerAccounts);
      }
    }
    if (selectedCustomerAccounts && selectedCustomerAccounts.length === 1) {
      setSelectedAccountSlug(selectedCustomerAccounts[0].slug);
    }

    return () => {};
  }, [customers, selectedClientSlug, selectedCustomerAccounts]);

  return (
    <>
      <Topbar
        open={open}
        setOpen={setOpen}
        pageDomainName={pageDomainName ?? ''}
        clientList={customers}
        accountsList={sites} //We pass all site list the user has access to so we can find the record of the relevant site name when state changes because of the dropdown selector
        pageClientSlug={pageClientSlug ?? ''}
        pageAccountSlug={pageAccountSlug ?? ''}
        userIsAuthenticated={userIsAuthenticated}
        userCanCreateIssue={userCanCreateIssue}
        hasManagementAPIToken={hasManagementAPIToken}
        userData={userData}
      />
      <Sidebar
        open={open}
        clientList={customers}
        accountsList={selectedCustomerAccounts}
        pageAccountSlug={pageAccountSlug ?? ''}
        canEditReports={canEditReports}
        selectedClientSlug={selectedClientSlug}
        managementAccessEnabled={managementAccessEnabled}
        isSuperuser={isSuperuser}
        handleChange={handleChange}
        setSelectedAccountSlug={setSelectedAccountSlug}
        selectedAccountSlug={selectedAccountSlug}
      />
    </>
  );
};

export default Navigation;
