import { typescriptAsConst } from '@/js/utils/typescriptAsConst';

export const routes = typescriptAsConst({
  internal: {
    auth_2fa_setup: () => '/accounts/2fa',
    auth_setPassword: () => '/accounts/set-password',

    client_overviewDashboard: () => '/cl-:clientSlug/dashboard',
    client_settingsUser: () => '/cl-:clientSlug/settings/users',
    client_archive: () => '/cl-:clientSlug/archive',
    client_reports: () => '/cl-:clientSlug/reports',
    client_publishedTests: () => '/cl-:clientSlug/testing',
    client_reportsEditor: () => '/cl-:clientSlug/reports/editor',
    client_reportsViewer: () => '/cl-:clientSlug/reports/editor/edit/:reportId',
    client_ipAllowLists: () => '/cl-:clientSlug/settings/ip_allowlists',

    site_dashboard: () => '/:accountSlug/dashboard',
    site_datasets: () => '/:accountSlug/datasets',
    site_editDomain: () => '/:accountSlug/edit',
    site_editDomainConfig: () => '/:accountSlug/config',
    site_infrastructureMonitoring: () => '/:accountSlug/stats',
    site_failoverSettings: () => '/:accountSlug/failover/overview',
    site_failoverSettings_ensureAllCreated: () =>
      '/:accountSlug/failover/ensure_all_created',

    site_originSettings: () => '/:accountSlug/backends/list',
    site_originSettings_editConfig: () => '/:accountSlug/backends/edit/:id',
    site_originSettings_editOriginServers: () =>
      '/:accountSlug/backends/origin_servers/:id',
    site_originSettings_addOriginServers: () => '/:accountSlug/backends/add',

    site_section: () => '/:accountSlug/:sectionSlug',
    site_section_rule_add: () =>
      '/:accountSlug/:sectionSlug/rule/add/:ruleType/',
    site_section_rule_edit: () => '/:accountSlug/:sectionSlug/rule/:ruleId/',
    site_section_rule_adjuster_add: () =>
      '/:accountSlug/:sectionSlug/rule/:ruleId/adjuster/add/',
    site_section_rule_adjuster_edit: () =>
      '/:accountSlug/:sectionSlug/rule/:ruleId/adjuster/:adjusterId/edit/',
    site_section_variable_add: () => '/:accountSlug/:sectionSlug/variable/add',
    site_section_variable_edit: () => '/:accountSlug/:sectionSlug/variable/:id',
    site_section_variable_addBulk: () =>
      '/:accountSlug/:sectionSlug/variable/bulk_add',

    site_tests_dashboard: () => '/:accountSlug/testing',
    site_tests_results: () => '/:accountSlug/testing/results/:testId',
    site_tests_results_editDates: () =>
      '/:accountSlug/testing/results/:testId/edit_dates',
    site_tests_edit: () => '/:accountSlug/testing/in_section_test/test/:testId',
    site_tests_create_resp: () =>
      '/:accountSlug/testing/in_section_test/section/:sectionSlug',
    site_tests_create_req: () =>
      '/:accountSlug/testing/in_section_test/request_section/:sectionId',

    site_requestSection_create: () => '/:accountSlug/request/',

    site_publish: () => '/:accountSlug/publish/live',
    site_publish_history: () => '/:accountSlug/publish/history',
    site_publish_compare: () => '/:accountSlug/publish/compare/',

    site_settings: () => '/:accountSlug/settings',
    site_settings_users: () => '/:accountSlug/settings/users',
    site_settings_editNotes: () => '/:accountSlug/settings/edit_notes',
    site_settings_site: () => '/:accountSlug/settings/edit',

    management_statusCake: () => '/management/statuscake',
    management_downloadRules: () => '/management/download_spec/:accountSlug',
    management_apiToken: () => '/management/api_token',
    management_ipAllowLists: () => '/management/global_ip_allowlists',
    management_redisAdmin: () => '/management/kv-admin',
    management_redisKeysAdmin: () => '/management/kv-admin/:region/:db',
    management_redisKeysAdminInspect: () =>
      '/management/kv-admin/:region/:db/:site',
    management_analyticsConfig: () => '/management/analytics_config',
    management_linearFullFunnel: () => '/management/linear_full_funnel',
    management_benchmarking: () => '/management/benchmarking',
    management_global_test_dashboard: () =>
      '/management/global_test_dashboard/',
  },
});
